import { ReactNode, useState, useEffect } from "react";
import clsx from "clsx";
import * as Dialog from "@radix-ui/react-dialog";
import { Icon } from "~/components/ui/icons";
import { useLocation, useNavigate } from "react-router";
import { Image } from "~/components/ui/image";
import { H4 } from "~/components/ui/typography";

type ModalProps = {
	id?: string;
	isOpen?: boolean;
	onClose?: () => void;
	children: ReactNode;
	className?: string;
	size?: "sm" | "md" | "lg";
};

export function Modal({
	isOpen: controlledOpen,
	onClose,
	children,
	id,
	className,
	size = "md",
}: ModalProps) {
	const location = useLocation();
	const navigate = useNavigate();

	const params = new URLSearchParams(location.search);
	const modalParam = params.get("m");

	const [isOpen, setIsOpen] = useState(controlledOpen ?? modalParam === id);

	useEffect(() => {
		setIsOpen(controlledOpen ?? modalParam === id);
	}, [modalParam, id, controlledOpen]);

	function close() {
		setIsOpen(false);

		if (params.has("m")) {
			params.delete("m");
			navigate(
				{ pathname: location.pathname, search: params.toString() },
				{ replace: true }
			);
		}

		if (onClose) {
			onClose();
		}
	}

	return (
		<Dialog.Root
			open={isOpen}
			onOpenChange={(open: boolean) => !open && close()}
		>
			<Dialog.Portal>
				<Dialog.Overlay className="fixed inset-0 z-50 bg-black/55" />
				<div className="fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2">
					<Dialog.Content
						className={clsx(
							"flex max-h-[90vh] w-[90vw] origin-center flex-col rounded bg-white",
							"data-[state=closed]:animate-fadeOutScale data-[state=open]:animate-fadeInScale",
							size === "lg"
								? "max-w-container"
								: size === "sm"
									? "max-w-[600px]"
									: "max-w-content",
							className
						)}
					>
						<Dialog.Close asChild>
							<button
								className="absolute right-3 top-3 p-3"
								aria-label="Close modal"
							>
								<Icon
									name="close-button"
									color="secondary"
									width="14"
									height="14"
								/>
							</button>
						</Dialog.Close>
						{children}
					</Dialog.Content>
				</div>
			</Dialog.Portal>
		</Dialog.Root>
	);
}

function HeaderImage({ backgroundImage }: { backgroundImage: string }) {
	return (
		<Image
			aria-hidden
			src={backgroundImage}
			className="h-[120px] min-h-[120px] w-full object-cover"
		/>
	);
}

function Header({
	title,
	subtitle,
	className,
}: {
	title: string;
	subtitle?: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={clsx("p-6 lg:p-7", className)}>
			<Dialog.Title asChild>
				<H4>{title}</H4>
			</Dialog.Title>
			{subtitle}
		</div>
	);
}

function Content({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div
			className={clsx("overflow-y-auto px-6 pb-6 lg:px-7 lg:pb-7", className)}
		>
			{children}
		</div>
	);
}

type FooterProps = {
	className?: string;
	children?: React.ReactNode;
};

export function Footer({ children, className }: FooterProps) {
	return (
		<div className={clsx("px-6 pb-6 lg:px-7 lg:pb-7", className)}>
			{children}
		</div>
	);
}

Modal.HeaderImage = HeaderImage;
Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;
